<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getListStatementAdvanceTrainning"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StatementAdvanceTraining',
  data () {
    return {
      headers: [
        { value: 'date_create', text: this.$t('createDate') },
        { value: 'number', text: this.$t('number') },
        { value: 'sailorFullName', text: this.$t('fullName'), sortable: false },
        { value: 'educational_institution', text: this.$t('nameInstitution') },
        { value: 'level_qualification', text: this.$t('qualification') },
        { value: 'date_meeting', text: this.$t('dateStartEdu') },
        { value: 'date_end_meeting', text: this.$t('dateEndEdu') },
        { value: 'is_payed', text: this.$t('payment') },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), class: 'mw-0', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: ({ sailor, id }) => ({ name: 'education-statements-info', params: { id: sailor.id, documentID: id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.statementAdvanceTraining,
      isLoading: state => state.statement.isLoading
    })
  },
  methods: {
    ...mapActions(['getListStatementAdvanceTrainning'])
  }
}
</script>
